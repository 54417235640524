import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-lafayette-louisiana.png'
import image0 from "../../images/cities/scale-model-of-lafayette-science-museum-in-lafayette-louisiana.png"
import image1 from "../../images/cities/scale-model-of-lafayette-parish-bayou-vermilion-district-in-lafayette-louisiana.png"
import image2 from "../../images/cities/scale-model-of-louisiana-folks-roots,-inc.-in-lafayette-louisiana.png"
import image3 from "../../images/cities/scale-model-of-la-maison-de-begnaud-in-lafayette-louisiana.png"
import image4 from "../../images/cities/scale-model-of-acadiana-in-lafayette-louisiana.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Lafayette'
            state='Louisiana'
            image={image}
            lat='30.2240897'
            lon='-92.01984270000003'
            attractions={ [{"name": "Lafayette Science Museum", "vicinity": "433 Jefferson St, Lafayette", "types": ["museum", "point_of_interest", "establishment"], "lat": 30.225595, "lng": -92.017807}, {"name": "Lafayette Parish Bayou Vermilion District", "vicinity": "300 Fisher Rd, Lafayette", "types": ["point_of_interest", "establishment"], "lat": 30.2143704, "lng": -91.99637330000002}, {"name": "Louisiana Folks Roots, Inc.", "vicinity": "905 Jefferson St Ste 304, Lafayette", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 30.2211585, "lng": -92.01817460000001}, {"name": "La Maison De Begnaud", "vicinity": "110 Benoit Patin Rd, Scott", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 30.243291, "lng": -92.10701999999998}, {"name": "Acadiana", "vicinity": "110 Travis St, Lafayette", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 30.2052275, "lng": -92.01384810000002}] }
            attractionImages={ {"Lafayette Science Museum":image0,"Lafayette Parish Bayou Vermilion District":image1,"Louisiana Folks Roots, Inc.":image2,"La Maison De Begnaud":image3,"Acadiana":image4,} }
       />);
  }
}